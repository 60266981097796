import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import Hero from '../components/Hero'
import NewsDetails from '../components/NewsDetails'
import Layout from '../components/Layout'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
// import { BLOCKS, INLINES } from "@contentful/rich-text-types"
// import RecentPosts from '../elements/Widgets/RecentPosts'
import Categories from '../elements/Widgets/Categories'

import SectionWithMultipleImages from '../components/SectionWithMultipleImages'

const SingleNews = ({ data }) => {
  const post = data.contentfulPageBlog
  const image = getImage(post.image)
  const categories = post.categories || null
  const tags = post.tags || null
  // const recentPosts = data.allContentfulPageBlog.edges || null
  const relatedProjects = post.relatedProjects || null

  let heroSubTitle = `<span className="single-news-date">${post.date}</span>`
  if (categories) {
    categories.map((item, i) => (heroSubTitle = heroSubTitle + `<a href="#" key=${i}>${item.title}</a>`))
  }

  let heroTitle = post.title

  return (
    <Layout>
      <Hero title={heroTitle} sub={heroSubTitle} class="single-news-hero" />
      <NewsDetails>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 single-news-left">
          <GatsbyImage image={image} alt={post.title} />
          <h3 className="big-title anim-bot">{post.headline}</h3>
          {post.body && renderRichText(post.body)}
          {/* {thisNews.blockquote.text ? 
                        <blockquote>
                            <p>
                                {thisNews.blockquote.text}
                            </p>
                            <cite>{thisNews.blockquote.author}</cite>
                        </blockquote>
                    : null } */}
          {/* {thisNews.textTwo.map((item, i) => <p key={i}>{item}</p>)} */}
          {tags && (
            <p className="tags">
              {tags.map((tag, i) => (
                <span key={i}>{tag.title}</span>
              ))}
            </p>
          )}
        </div>

        <div className="col-12 col-sm-8 col-md-8 col-lg-6 col-xl-4 single-news-right sidebar">
          {/* {recentPosts && <RecentPosts recentPosts={recentPosts} />} */}
          {categories && <Categories categories={post.categories} />}
        </div>

        <div className="col-12 col-sm-4 col-md-4 col-lg-6"></div>
      </NewsDetails>
      {relatedProjects && 
        <SectionWithMultipleImages data={relatedProjects} isWork />}
    </Layout>
  )
}

export default SingleNews

export const query = graphql`
  query articleQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPageBlog( slug: { eq: $slug } )  {
      id
      title
      slug
      image {
        gatsbyImageData(aspectRatio: 1.5, layout: FULL_WIDTH, width: 1680, placeholder: BLURRED, formats: [AUTO, WEBP])
      }
      headline
      date(formatString: "DD MMMM YYYY")
      body {
        raw
      }
      categories {
        id
        title
        slug
      }
      tags {
        id
        title
        slug
      }
      relatedProjects {
        id
        url
        images {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        headline
        slug
        client
      }
    }
    allContentfulPageBlog(sort: { fields: date }, limit: 5, filter: { slug: { ne: $slug } }) {
      edges {
        node {
          date(formatString: "DD MMMM YYYY")
          title
          slug
        }
      }
    }
  }
`
