import React from 'react'

function Categories({ categories }) {
  const categoryList = categories.map((category, index) => (
    <li>
      <a href='#'>{category.title}</a>
    </li>
  ))
  return (
    <>
      <div className="widget">
        <h5 className="sub-title"><b>Categories</b></h5>
        <ul>{categoryList}</ul>
      </div>
    </>
  )
}

export default Categories
